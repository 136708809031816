import { route } from 'ziggy-js'
import useAxios from '@/useAxios'
import type UserBackend from '@/Backend/models/UserBackend'

export function useListUsers(params = {}, options = {}) {
  return useAxios<{ data: UserBackend[]; meta: any }>(
    route('api.v1.users.list'),
    {
      method: 'get',
      params: params
    },
    {
      immediate: true,
      ...options
    }
  )
}
export function useGetUser(userId: number) {
  return useAxios<{ data: UserBackend }>(route('api.v1.users.get', userId), {
    method: 'get'
  })
}

export function useUpdateUser(data = {}) {
  return useAxios<{ data: UserBackend }>(
    route('api.v1.users.update'),
    {
      method: 'put',
      data: data
    },
    { immediate: false }
  )
}

export function useUpdateUserPhoto(data = {}) {
  return useAxios(
    route('api.v1.users.photo.update'),
    {
      method: 'post',
      data: data
    },
    { immediate: false }
  )
}

export function useListBankHolidays(userId: number, params = {}) {
  return useAxios(
    route('api.v1.users.bank-holidays.list', { user: userId }),
    {
      method: 'get',
      params
    },
    { immediate: true }
  )
}
