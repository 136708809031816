import { useAxios as defaultUseAxios } from '@vueuse/integrations/useAxios'
import axios from '@/axios'
import type { AxiosRequestConfig } from 'axios'
import type { UseAxiosOptions } from '@vueuse/integrations'
import { computed } from 'vue'

const useAxios = <T, E = { [s: string]: string }>(
  url: string,
  config: AxiosRequestConfig = {},
  options: UseAxiosOptions<T> | undefined = undefined
) => {
  const { error, execute: axiosExecute, ...props } = defaultUseAxios<T>(url, config, axios, options)

  const execute = (url?: string | AxiosRequestConfig, config?: AxiosRequestConfig) => {
    if (url && typeof url !== 'string' && 'data' in url && 'isDirty' in url.data) {
      delete url.data.isDirty
    }
    if (config && 'data' in config && 'isDirty' in config.data) {
      delete config.data.isDirty
    }
    return axiosExecute(url, {
      ...config
    }).then((props) => {
      if (url && typeof url !== 'string' && 'data' in url && 'resetInitialData' in url.data) {
        url.data.resetInitialData()
      }

      if (config && 'data' in config && 'resetInitialData' in config.data) {
        config.data.resetInitialData()
      }

      return props
    })
  }
  const errors = computed(() => {
    if (!error.value) {
      return {} as E
    }
    const tempErrors =
      (
        error.value as {
          response: {
            data: {
              errors: {
                [k: string]: string[]
              }
            }
          }
        }
      ).response?.data?.errors || {}

    return Object.keys(tempErrors).reduce<E>((simplifiedErrors, key) => {
      simplifiedErrors = {
        ...simplifiedErrors,
        [key]: tempErrors[key][0]
      }
      return simplifiedErrors
    }, {} as E)
  })

  const errorMsg = computed(() => {
    return Object.entries(errors.value as { [s: string]: string })
      .map(([key, msg]) => `${key}: ${msg}`)
      .join('\n')
  })

  return {
    ...props,
    execute,
    error,
    errors,
    errorMsg
  }
}

export default useAxios
